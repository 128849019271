import React from 'react';
import {Link} from 'react-router-dom';
const CollectionItems = [
  {
    img1: '1',
    img2: '2',
    img3: '3',
    img4: '4',
    title: 'Weedheads Art collection',
    likes: '2.1',
    stock: '5',
    avatar_img: '1',
    avatar_name: 'wakuwaku',
  },
  {
    img1: '13',
    img2: '14',
    img3: '14',
    img4: '16',
    title: 'Colorful Abstract collection',
    likes: '3.5',
    stock: '7',
    avatar_img: '2',
    avatar_name: 'ILOVETRAINS!',
  },
  {
    img1: '17',
    img2: '18',
    img3: '19',
    img4: '20',
    title: 'Photography Art collection',
    likes: '7.2',
    stock: '2',
    avatar_img: '3',
    avatar_name: 'machomigoo',
  },
  {
    img1: '200',
    img2: '248',
    img3: '417',
    img4: '418',
    title: 'Weedheads Art collection',
    likes: '2.1',
    stock: '5',
    avatar_img: '4',
    avatar_name: 'killmost',
  },
  {
    img1: '21',
    img2: '22',
    img3: '23',
    img4: '24',
    title: 'Bored Ape Art collection',
    likes: '3.5',
    stock: '7',
    avatar_img: '5',
    avatar_name: 'SherriffB',
  },
  {
    img1: '25',
    img2: '26',
    img3: '28',
    img4: '27',
    title: 'CryptoPunks Art collection',
    likes: '7.2',
    stock: '2',
    avatar_img: '1',
    avatar_name: 'whelan',
  },
];
function Collection3() {
  return (
    <div>
      <div className="row justify-content-center mb-30_reset">
        {CollectionItems.map((val, i) => (
          <div className="col-lg-4 col-md-6 col-sm-8" key={i}>
            <div className="collections space-y-10 mb-30">
              <div className="collections_item">
                <Link to="profile" className="images-box space-y-10">
                  <div className="top_imgs">
                    <img src={`img/weedheads/collection/item_${val.img1}.png`} alt="prv" />
                    <img src={`img/weedheads/collection/item_${val.img2}.png`} alt="prv" />
                    <img src={`img/weedheads/collection/item_${val.img3}.png`} alt="prv" />
                  </div>
                  <img src={`img/weedheads/collection/item_${val.img4}.png`} alt="prv" />
                </Link>
              </div>
              <div className="collections_footer justify-content-between">
                <h5 className="collection_title">
                  <Link to="profile">{val.title}</Link>
                </h5>
                <Link to="#" className="likes space-x-3">
                  <i className="ri-heart-3-fill" />
                  <span className="txt_md">{val.likes}k</span>
                </Link>
              </div>
              <div className="creators space-x-10">
                <span className="color_text txt_md">
                  {val.stock} items · Created by
                </span>
                <div className="avatars space-x-5">
                  <Link to="profile">
                    <img
                      src={`img/nfteaseavs/${val.avatar_img}.svg`}
                      alt="Avatar"
                      className="avatar avatar-sm"
                    />
                  </Link>
                </div>
                <Link to="profile">
                  <p className="avatars_name txt_sm"> @{val.avatar_name} </p>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Collection3;
