import React from 'react';
import {Link} from 'react-router-dom';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import useDocumentTitle from '../../../components/useDocumentTitle';
import {ToastContainer, toast} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const WeedheadsCta = () => {
  // useDocumentTitle(' Newsletter');
  const sending = () => toast('you will receive daily updates');

  return (
    <div>
      {/*<Header />*/}
      <div className="container">
        <div className="hero_newsletter box bg_white">
          <div className="row gx-5 align-items-center">
            <div className="col-lg-6 left__side">
              <div className="content space-y-20 space-x-20">
                <h1 className="hero__title">
                  50% <span className='text-info'>Profit Share</span> with Weedheads NFT Holders
                </h1>
                <p className="hero__desc">

                  
                  ✅ An utility for the Weedheads NFT holders to be a profit share holder
                  of NFTease platform. <br/>

                  ✅ 10% Already alloted via Private Sale. <br/>

                  ✅ Public mint starting soon. Get your Weedheads NFT and be a part of our ecosystem.  

                </p>
                
                <div
                className="space-x-20 d-flex flex-column flex-md-row
							sm:space-y-20 py-20">
                <a className="btn btn-primary" href='https://discord.gg/weedheads'>
                  Join Discord
                </a>
                <a className="btn btn-white" href="https://weedheads.art">
                  Check Roadmap
                </a>
              </div>
              </div>
            </div>
            <div className="col-lg-6 right__side weedheads-cta-image" >
              <img
                src={`https://www.weedheads.art/assets/images/weedheads-anim-800x800.gif`}
                alt="newsletter"
                className="img-fluid hero__img"
                style={{width:"70%"}}
              />
            </div>
          </div>
        </div>
      </div>
      {/*<Footer />*/}
    </div>
  );
};

export default WeedheadsCta;
