import React from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import useDocumentTitle from '../../../components/useDocumentTitle';
import {HashLink} from 'react-router-hash-link';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import HeroQuestions from '../../../components/hero/HeroQuestions';
import {Link} from 'react-router-dom';

const FaqContent = [
  {
    title: 'What\'s in it for Weedheads Presale NFT holder?',
    expand: 'a',
    desc:`PROFIT SHARING - 10% of the Net Profit generated from the NFTeees platform will be distributed among the holders of the presale batch of 420 NFTs. 

    Your share in the total profit will be calculated using the total rarity score of all your owned Weedheads NFTs. In layman terms, the more Weedheads NFTs you own from the presale, the greater your individual profit share will be.`,
    link: 'General',
  },
  {
    title: 'When will the profit sharing be done? ',
    desc: `The profit sharing shall be done every quarter after the Stable version launch.`,
    expand: 'b',
    link: 'Support',
  },
  {
    title: ' Which all blockchains this platform will support?',
    desc: `We will support all leading blockchain based platforms such as ETH, SOL, XTZ (Tezos), Polygon & more.`,
    expand: 'c',
    link: 'Transaction',
  },
  {
    title: 'Do I have to be a Weedheads NFT holder to get my NFTease Show off page?',
    desc: `No, you do not necessarily need to be a Weedheads NFT holder to register with NFTeees platform. NFTeees invites collectors irrespective of their NFT collections.`,
    expand: 'd',
    link: 'Fees',
  },
  {
    title: 'How will this platform generate revenue?',
    desc: `The platform will introduce subscription plans to choose from, run advertisements, collaborate with other brands etc.`,
    expand: 'e',
    link: 'Fees',
  },
  
];

const Faq = () => {
  useDocumentTitle('Faq');
  return (
    <div>
      <Header />
      <HeroQuestions />
      <div>
        <div className="questions__page mt-100">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="row">
                {/* <div className="col-lg-3 col-md-3 col-sm-4">
                  <div className="box side">
                    <div className="sidenav">
                      <ul>
                        <li className="d-flex align-items-center space-x-10">
                          <i className="ri-home-2-line" />
                          <HashLink
                            className="text__reset"
                            to="#General"
                            scroll={(el) =>
                              el.scrollIntoView({block: 'start'})
                            }>
                            General
                          </HashLink>
                        </li>
                        <li className="d-flex align-items-center space-x-10">
                          <i className="ri-chat-1-line" />
                          <Link className="text__reset" to="#Support">
                            Support
                          </Link>
                        </li>
                        <li className="d-flex align-items-center space-x-10">
                          <i className="ri-cloudy-line" />
                          <Link className="text__reset" to="#Transaction">
                            Transaction
                          </Link>
                        </li>
                        <li className="d-flex align-items-center space-x-10">
                          <i className="ri-quill-pen-line" />
                          <HashLink
                            className="text__reset"
                            to="#Fees"
                            scroll={(el) =>
                              el.scrollIntoView({block: 'start'})
                            }>
                            Fees
                          </HashLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> */}
                <div className="xcol-lg-9 xcol-md-9 xcol-sm-8">
                  <div className="questions__box space-y-30">
                    <Accordion
                      className="ff"
                      preExpanded={['b']}
                      allowZeroExpanded>
                      {FaqContent.map((item, i) => (
                        <AccordionItem
                          id={item.link}
                          className="accordion p-30 mb-20"
                          key={i}
                          uuid={item.expand}>
                          <AccordionItemHeading className="accordion-header p-0">
                            <AccordionItemButton>
                              <button className="accordion-button">
                                {item.title}
                              </button>
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          {/* Accordion Heading */}
                          <AccordionItemPanel>
                            <p className="accordion-desc">{item.desc}</p>
                          </AccordionItemPanel>
                          {/* Accordion Body Content */}
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
