import React from 'react';

function HeroQuestions() {
  return (
    <div>
      <div className="hero_questions bg_white">
        <div className="container">
          <div className="space-y-20">
            <h1 className="text-center">Frequently asked questions</h1>
            <p className="text-center">
              Join us on <a href='https://discord.gg/weedheads'>Discord Server</a> if your questions aren't answered below. Weedheads Crew will be happy to help you out.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroQuestions;
