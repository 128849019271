import React from 'react';
import Footer from '../../../components/footer/Footer';
import Header from '../../../components/header/Header';
import useDocumentTitle from '../../../components/useDocumentTitle';
import {Link} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import presaleHolder from "../../../assets/presale.json";
const Investors = () => {
  const notify = () => toast.success('We have recieved your message');

  useDocumentTitle(' NFTease Early Investors');


  return (
    <div>
      <Header />
      <div>
      <div className="hero_questions bg_white">
        <div className="container">
          <div className="space-y-20">
            <h1 className="text-center">Our Early Investors</h1>
            <p className="text-center">
              Weedheads NFT presale holders who owns 10% Profit share in NFTease platform.
            </p>
            <div className='text-center'>

              <a class="btn btn-primary" href="https://discord.gg/weedheads">Join The Crew</a>
            </div>
          </div>
        </div>
      </div>

      <div>

      <div className="section__body ">
              <div className="row mb-20_reset justify-content-center p-2 p-md-5">
                {presaleHolder.map((val, i) => (
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-sm-1 mb-md-5 "  key={i}>
                    <div
                      className="creator_item creator_card space-x-10 "
                     >
                      <div className="avatars space-x-10">
                          <img
                            src={val.avatar_url}
                            alt="Avatar"
                            className="avatar avatar-md"
                          />
                        <div>
                            <p className="avatars_name color_black">
                              @{val.display_name}
                            </p>
                          <span className="price color_green">
                            {/* {val.price} SOL */}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
      </div>
    </div>
      <div style={{
        height:"8.5vh"
      }}/>
      <Footer />
    </div>
  );
};

export default Investors;
