import React from 'react';
import {Link} from 'react-router-dom';

const CreatorsItems = [
  {
    img: '1',
    name: 'whelan',
    price: '160.58',
  },
  {
    img: '2',
    name: 'KingMango',
    price: '140.55',
  },
  {
    img: '3',
    name: 'killmost',
    price: '240.13',
  },
  {
    img: '4',
    name: 'machomigoo',
    price: '162.68',
  },
  {
    img: '5',
    name: 'VaxaVidr',
    price: '132.48',
  },
  {
    img: '1',
    name: 'ILOVETRAINS!',
    price: '150.02',
  },
  {
    img: '2',
    name: 'wakuwaku',
    price: '90.99',
  },
  {
    img: '3',
    name: 'Shaman',
    price: '52.58',
  },
  {
    img: '4',
    name: 'QuickKush',
    price: '140.58',
  },
  {
    img: '5',
    name: 'SherriffB',
    price: '120.24',
  },
  {
    img: '4',
    name: 'CapnCrump',
    price: '166.58',
  },
  {
    img: '2',
    name: 'WhiteWidow',
    price: '128.58',
  },
];
function PopularCreators() {
  return (

      <div className="section__creators mt-100">
        <div className="container">
          <div className="">
            <div className="section_head mb-30">
              <h2 className="section__title text-center">Popular Collectors</h2>
            </div>
            <div className="section__body">
              <div className="row mb-20_reset justify-content-center">
                {CreatorsItems.map((val, i) => (
                  <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-20"  key={i}>
                    <div
                      className="creator_item creator_card space-x-10 "
                     >
                      <div className="avatars space-x-10">
                        <Link to="Profile">
                          <img
                            src={`img/nfteaseavs/${val.img}.svg`}
                            alt="Avatar"
                            className="avatar avatar-md"
                          />
                        </Link>
                        <div>
                          <Link to="profile">
                            <p className="avatars_name color_black">
                              @{val.name}
                            </p>
                          </Link>
                          <span className="price color_green">
                            {val.price} SOL
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

  );
}

export default PopularCreators;
