import React from 'react';
import {Link} from 'react-router-dom';

const Hero1 = () => {
  return (
    <div className="hero__1">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="hero__left space-y-20">
              <h1 className="hero__title">
                Tease your NFTs with your own style
              </h1>
              <p className="hero__text txt">
                A platform to showcase your collections owned from
                Solana, Ethereum, Polygon and many other blockchains.
                NFTease let you have a fully customized showcase page with a capability to
                turn into a marketplace in future.
              </p>
              <div
                className="space-x-20 d-flex flex-column flex-md-row
							sm:space-y-20">
                <Link className="btn btn-primary" to="collections">
                  View Collections
                </Link>
                <Link className="btn btn-white" to="connect-wallet">
                  Connect Wallet
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img
              className="img-fluid w-full"
              id="img_js"
              src="img/weedheads/nfteasehome.jpg"
              alt="img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero1;
