import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../../components/header/Header';
import useDocumentTitle from '../../../components/useDocumentTitle';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../../assets/scss/customcss.css"
// import GoogleLoginComponent from "../../../components/login/googlebutton";


const Login = () => {
  useDocumentTitle('Login ');
  // const notify = () => toast.success('Your account is  verified ');

  return (
    <div>
      <Header />
      <div className="edit_profile register login">
        <div className="container" style={{float:"right"}}>
          <div className="row">
            <div className="col-lg-7" />
            <div className="col-lg-5">
              <div className="right_part space-y-20">
                <div>
                  <h1 className="color_white"> Welcome to
                    <span className="text_pink-blue"> Weedheads </span>
                    NFTease Platform </h1>
                </div>
                <p className="color_white" style={{ color: 'white !important' }}>
                  Don't have an account yet?
                  <Link to="register"> Register </Link>
                </p>
                <div className="box edit_box w-full space-y-20">

                  <div style={{
                    paddingBottom: 13,
                    textAlign: "center"
                  }}>
                    <h2>
                      Weedheads Login
                    </h2>
                  </div>

                  {/*<GoogleLoginComponent/>*/}

                  <Link
                    className="btn bg_pink-blue w-full btn-lg"
                    to="#">
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <i className="ri-google-line" style={{
                        fontSize: "25px",
                        height: "3vh"
                      }} />
                      <div style={{ flexGrow: 1 }} />
                      Google
                      <div style={{ flexGrow: 1 }} />
                    </div>
                  </Link>

                  <Link
                    className="btn bg_pink-blue w-full btn-lg"
                    to="#">
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <i className="ri-twitter-line" style={{
                        fontSize: "25px",
                        height: "3vh"
                      }} />
                      <div style={{ flexGrow: 1 }} />
                      Twitter
                      <div style={{ flexGrow: 1 }} />
                    </div>
                  </Link>

                  <Link
                    className="btn bg_pink-blue w-full btn-lg"
                    to="#">
                    <div style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center"
                    }}>
                      <i className="ri-facebook-line" style={{
                        fontSize: "25px",
                        height: "3vh"
                      }} />
                      <div style={{ flexGrow: 1 }} />
                      Facebook
                      <div style={{ flexGrow: 1 }} />
                    </div>
                  </Link>

                  <ToastContainer position="bottom-right" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
